@import 'react-big-calendar/lib/sass/styles';

.App {
  text-align: center;

  .spinner {
    position: fixed;
    top: calc(50% - 16px);
  }

  .mbta-station-list {
    li {
      a {
        margin: 0 auto;
      }
    }
  }
  .mbta-prediction-container {
    margin: 2rem auto 0;
    width: 320px;
    color: white;
    background: rgba(black, 0.3);
    border-radius: .5rem;
    padding: .5rem;
    .background-image {
      background-image: url('../public/images/tracks-desktop.png');
      position: fixed;
      left: 0;
      top: 0;
      z-index: -1;
      background-size: cover;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      height: 100vh;
      width: 100%;
    }
  }
}

@media screen and (max-width: 600px) {
  .App {
    .mbta-prediction-container {
      .background-image {
        background-image: url('../public/images/tracks-mobile.png');
      }
    }
  }
}
